export const Transparent = {
  backgroundColor: "none",
  label: "Sign In",
  borderRadius: "0px",
  border: "1px solid #707070",
  padding: "8px 20px",
  cursor: "pointer",
};

export const Capsule = {
  backgroundColor: "#FF7B16",
  label: "TRANSLATE NOW",
  border: "none",
  borderRadius: "24px",
  padding: "10px 15px",
  cursor: "pointer",
  fontWeight: "500",
};

export const Primary = {
  backgroundColor: "#ff7b16",
  label: "SUBMIT",
  border: "none",
  borderRadius: "0px",
  padding: "8px 20px",
  cursor: "pointer",
};
export const Rounded = {
  backgroundColor: "#ff7b16",
  label: "SUBMIT",
  border: "none",
  borderRadius: "20px",
  padding: "8px 20px",
  cursor: "pointer",
};

export const Sorting = {
  backgroundColor: "#000C27",
  border: "none",
  borderRadius: "5px",
  padding: "8px 20px",
  cursor: "pointer",
};

export const Secondary = {
  backgroundColor: "#3A7AFF",
  label: "SUBMIT",
  border: "none",
  borderRadius: "0px",
  padding: "14px 32px",
  cursor: "pointer",
};
