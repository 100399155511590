import React from "react";
import { Link } from "gatsby";

function MultilingualFifth() {
  return (
    <div className="bg-midBlueNew">
      <div className="w-11/12 lg:w-10/12 2xl:w-9/12 max-w-7xl mx-auto pt-[64px] pb-[54px]">
        {/* <h2 className="text-45 text-orange leading-[65px] font-secondary font-medium mb-7">
          For Website Localization:
          <span className="block text-white"> Multilingual SEO Done Right</span>
        </h2>
        <p className="text-lg leading-8 text-white font-opensans mb-7">
          If you have a website, you would need to have the assurance of
          translation and localization experts as well as SEO experts behind
          you—boosting your brand reputation all over the known world. At
          Tomedes, we’re more than just translators—we’re remote and ready to
          handle your website translation. Our team of SEO strategists, Off-page
          and On-page SEO specialists and more will make sure your website is
          optimized.
        </p> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:pr-12 gap-4 xl:gap-8 mb-14">
          <h2 className="text-40 leading-[60px] text-white font-secondary font-medium">
            Set up your total website localization strategy for success
          </h2>
          <p className="text-lg leading-8 text-gray font-opensans">
            <span className="text-orange">Multilingual SEO</span> is always an
            integral element of doing website localization for different
            countries or languages.
            <span className="block pt-7">
              Tomedes offers full-suite website localization services among its
              core offerings, should you require a more comprehensive solution
              for your multilingual website project.
            </span>
          </p>
        </div>
        <div className="w-full bg-GlobalContact bg-cover bg-no-repeat mx-auto py-[20px] px-6 text-white">
          <div
            className=" flex flex-col lg:flex-row md:items-center justify-around"
            style={{ margin: " 2% 10%" }}
          >
            <p className="text-[26px] text-center sm:text-left font-secondary font-medium w-full">
              Start Localizing Your Website With SEO
            </p>
            <div className="my-8 md:my-0 w-full pt-4 lg:pt-0 lg:w-[30%] flex justify-center mx-auto">
              <Link
                to="/contactus.php"
                className="font-opensans font-bold text-17 text-center uppercase rounded-full bg-midBlue text-white cursor-pointer  py-4 px-8 w-[253px] hover:bg-[#012A68]"
              >
                CONTACT US NOW
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultilingualFifth;
