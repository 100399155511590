import React, { useEffect, useState } from "react";

import ThankYouPopup from "components/popup/thankYouPopup";
import { Link } from "gatsby";

import Servicesicon from "components/homepage/translationServices/servicesicon";
import EnquiryForm from "components/EnquiryForm";

function MultilingualBanner() {
  const [open, setOpen] = useState(false);

  const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState();
    useEffect(() => {
      (async () => {
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            setVariant(window.google_optimize.get(experimentId));
            clearInterval(intervalId);
          }
        }, 100);
      })();
    }, []);
    return variant;
  };
  // const variant = 1;
  const variant = useExperiment("_b1eORemTP6uefA63zaTlQ");
  return (
    <section className="bg-gradient1  relative bg-desktop bg-cover   sm:px-0 pt-5 md:pt-16 pb-8 ">
      <div className="gradient px-2">
        {/* <div className="md-transparent md:bg-gradient-to-t from-[#00173A] to-transparent h-full "> */}
        <div className="max-w-7xl mx-auto flex flex-col">
          <div className="flex flex-col lg:flex-row gap-6 xl:gap-0 w-full px-3 sm:px-0  mx-auto justify-between items-start ">
            <div className="">
              <div className="pb-5">
                <ul className=" gap-8 lg:flex ">
                  <li className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                        fill="#4285F4"
                      />
                    </svg>
                    <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                      Available Online 24/7
                    </p>
                  </li>

                  <li className="flex items-center my-4 lg:my-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                        fill="#4285F4"
                      />
                    </svg>
                    <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                      1-Year Guarantee
                    </p>
                  </li>

                  <li className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                        fill="#4285F4"
                      />
                    </svg>
                    <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                      95,000 Business Customers
                    </p>
                  </li>
                </ul>
              </div>
              <h1 className="text-[35px] sm:text-[40px] leading-[55px] font-bold font-secondary text-midBlue mb-5 sm:mb-8 w-full md:w-full lg:w-[85%]">
                Multilingual SEO Services
                <br />
              </h1>

              <p className="text-base leading-6 font-opensans text-midBlue mb-6 hidden sm:block w-full md:w-full lg:w-[85%]">
                Take your website to the top of search pages across the world,
                without a doubt in mind. Drive more organic traffic to your
                foreign-language pages and become visible on the world stage,
                with Tomedes as your partner in language.
              </p>
              <p className="text-base leading-6 font-opensans text-midBlue mb-6 hidden sm:block w-full md:w-full lg:w-[85%]">
                Our multilingual SEO services are the keystone of our
                comprehensive website localization suite, which can be tailored
                to fit your project’s specific needs. Contact us now, and our
                agent will connect you with one of our SEO experts to get you
                started.
              </p>
              <p className="text-base leading-6 font-opensans text-midBlue mb-6 sm:hidden">
                Drive more organic traffic to your foreign-language website
                pages and become visible on the world stage, with Tomedes as
                your partner in language.
              </p>
              <div className="btn mt-12">
                <Link
                  to="/quote"
                  className="uppercase rounded-full bg-orange hover:bg-newOrange-100 text-white cursor-pointer text-17 font-semibold py-4 px-8 w-[253px] text-center transition-colors duration-300"
                >
                  {variant == "1" ? "START TRANSLATION" : "TRANSLATE NOW"}
                </Link>
              </div>
            </div>
            <ThankYouPopup open={open} setOpen={setOpen} />
            <div>
              <EnquiryForm
                title="Get in touch"
                highlightWords="Get in touch"
                highlightedColor="orange"
                subtitle="We guarantee a reply in a few minutes."
              />
            </div>
          </div>
          <div className="w-full pt-[80px] pb-[60px]">
            <Servicesicon />
          </div>{" "}
          {/* <UsersReviews reviewsData={reviewsData} containerStyling="pt-5" /> */}
        </div>
      </div>
    </section>
  );
}
export default MultilingualBanner;
