import React from "react";
import Layout from "components/layout";
import MultilingualSeoServices from "../../components/MultilingualSeoServices";
import SEO from "components/seo";

function Index() {
  const title =
    "Multilingual SEO Services for Website Translation and Localization";
  const description =
    "Multilingual SEO services that will ensure that your translated web content will dominate the front page of search engines around the world. Get global traffic now.";
  const keywords = "";
  const slug = "/website-translation/multilingual-seo-services";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      <MultilingualSeoServices />
    </Layout>
  );
}

export default Index;
