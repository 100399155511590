import React from "react";
import ReviewSvg from "assets/images/review_stars.svg";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
import RatingCarousel from "./Carousel";

function MultilingualSixth() {
  return (
    <section className="bg-white sm:bg-multilingualGradient bg-no-repeat bg-cover bg-center pt-[80px] pb-[64px]">
      <div className="container mx-auto px-4 max-w-6xl">
        <div>
          <h2 className="font-secondary text-40 font-bold text-midBlue text-center">
            4.9 out of 5 Aggregate Rating
          </h2>
          <p className="font-secondary font-normal text-24 text-midBlue mt-4 text-center">
            (Out of 1798 Verified Reviews Online)
          </p>
          <img
            src={ReviewSvg}
            alt="review star"
            className="mt-5 mb-2 sm:w-[342px] sm:mx-auto"
          />
          <div className="flex flex-wrap justify-center gap-5 mt-6">
            <a href="https://www.g2.com/products/tomedes/reviews" target="_blank">
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1664346519g2.png"
                alt="g2 reviews"
                className="w-52 h-auto"
              />
            </a>
            <a href="https://www.facebook.com/Tomedes.translation/reviews" target="_blank">
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1664191195facebook.png"
                alt="fb reviews"
                className="w-52 h-auto"
              />
            </a>
            <a href="https://www.featuredcustomers.com/vendor/tomedes" target="_blank">
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1664191256featured.svg"
                alt="featured reviews"
                className="w-52 h-auto"
              />
            </a>
          </div>
        </div>
        <div className="hidden sm:grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 md:gap-y-7 xl:gap-36 justify-items-center mt-9">
          <Card1 />
          <Card2 />
          <Card3 />
        </div>
      </div>
      <div className="sm:hidden px-4">
        <RatingCarousel />
      </div>
    </section>
  );
}
export default MultilingualSixth;
