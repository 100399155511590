import React from "react";

function MultilingualSeoProjectManagement() {
  return (
    <div className="bg-white sm:bg-multilingualGradient bg-no-repeat bg-cover bg-center py-20">
      <div className="w-11/12 mx-auto lg:w-full">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-40 leading-[50px] sm:text-45 sm:leading-[65px] text-midBlue font-secondary font-bold mb-7 sm:text-center">
            Project management
            <br />
            and communication <span className="text-orange">made easy</span>
          </h2>
          <p className="text-lg leading-8 text-midBlue font-opensans sm:text-center">
            We will match you with a dedicated project manager who will oversee
            your account from start to finish. Our customer service line is open
            24/7, ensuring that all your concerns are met at your convenience.
          </p>
        </div>
      </div>
    </div>
  );
}

export default MultilingualSeoProjectManagement;
