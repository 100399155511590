import React from "react";
import { FAQData } from "./FAQConfig";
import MultilingualSeoAccordion from "./MultilingualSeoAccordion";

function MultilingualSEOFAQ() {
  const newBg = {
    background:
      "transparent linear-gradient(180deg, #E8F1F5 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  };

  return (
    <div
      className="bg-linearBlue bg-cover bg-center bg-no-repeat flex items-center justify-center py-14 md:py-24"
      style={newBg}
    >
      <div className="flex items-center justify-center max-w-screen-xl px-4 md:px-0 flex-col w-full lg:w-7/12">
        <h2 className="text-center font-secondary font-bold text-[45px] text-midBlue mb-10">
          FAQs
        </h2>
        <div className="flex items-center justify-center w-full  flex-col ">
          {FAQData.map((item, i) => (
            <MultilingualSeoAccordion {...item} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MultilingualSEOFAQ;
