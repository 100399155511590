import React from "react";
import MultilingualBanner from "./MultilingualBanner";
import UsersReviews from "../reusableComponents/UsersReviews/UsersReviews";
import { reviewsData } from "../reusableComponents/UsersReviews/reviewsConfig";
import MultilingualSecond from "./MultilingualSecond";
import MultilingualThird from "./MultilingualThird";
import MultilingualFifth from "./MultilingualFifth";
import MultilingualSixth from "./MultilingualSixth";
import MultilingualSeventh from "./MultilingualSeventh";
import MultilingualEighth from "./MultilingualEighth";
import MultilingualThirdNew from "./MultilingualThirdNew";
import MultilingualSeoPricing from "./MultilingualSeoPricing";
import MultilingualSEOFAQ from "./MultilingualSEOFAQ";
import MultilingualSeoCertificates from "./MultilingualSeoCertificates";
import GetInTouch from "components/getInTouch";
import MultilingualFourth from "./MultilingualFourth";
import MultilingualSeoProjectManagement from "./MultilingualSeoProjectManagement";

function MultilingualSeoServices() {
  return (
    <>
      <MultilingualBanner />
      <UsersReviews
        reviewsData={reviewsData}
        containerStyling="bg-[#012458] py-16"
      />
      <MultilingualSecond />
      {/* <MultilingualThird /> */}
      <MultilingualFourth />
      <MultilingualThirdNew />
      <MultilingualSeoProjectManagement />
      <MultilingualFifth />
      <MultilingualSeoPricing />
      <MultilingualSixth />
      {/* <MultilingualSeventh /> */}
      {/* <MultilingualEighth /> */}
      <MultilingualSeoCertificates />
      <MultilingualSEOFAQ />
      <GetInTouch />
    </>
  );
}

export default MultilingualSeoServices;
