import React, { useState } from "react";
import img2 from "assets/images/multilingual-seo-services/img2.webp";
import { Link } from "gatsby";
import {
  plansData,
  planBronzeData,
  planSilverData,
  planPlatinumData,
} from "./PlansConfig";
import chevronDown from "assets/chevron-down-midblue.svg";

function MultilingualSeoPricing() {
  const [plan, setPlan] = useState(1);
  const [openPlan, setOpenPlan] = useState({
    bronze: false,
    silver: false,
    platinum: false,
  });

  const planStyle1 = {
    background: "#003687 0% 0% no-repeat padding-box",
    boxShadow: "6px -2px 15px #00000029",
    color: "#EFFAFF",
  };

  const planStyle2 = {
    background: "#EFFAFF 0% 0% no-repeat padding-box",
    color: "#00173A",
  };

  return (
    <div>
      <div className="flex flex-col w-11/12 lg:w-10/12 2xl:w-9/12 max-w-7xl mx-auto py-[80px] mb-8">
        <h2 className="text-[55px] leading-[55px] text-midBlue text-center  font-secondary font-semibold mb-10">
          Pricing
        </h2>
        <div>
          <div className="grid grid-cols-2">
            <p
              className="text-xl text-center font-opensans font-bold py-6 cursor-pointer"
              style={plan == 1 ? planStyle1 : planStyle2}
              onClick={() => setPlan(1)}
            >
              SEO Plans / Packages
              <span className="text-sm leading-6 font-normal pt-2 px-4 hidden lg:block">
                Full-suite multilingual SEO services. Progressive optimization
                and maintenance over 6 months.
              </span>
            </p>
            <p
              className="text-xl text-center font-opensans font-bold py-6 cursor-pointer"
              style={plan == 2 ? planStyle1 : planStyle2}
              onClick={() => setPlan(2)}
            >
              One Shot
              <span className="text-sm leading-6 font-normal pt-2 px-4 hidden lg:block">
                For custom orders, our agents will be happy to assist you in
                drawing up a quote.
              </span>
            </p>
          </div>
          {/* pricingBorder in table for exp */}
          <div className="hidden xl:block">
            {plan == 1 ? (
              <table class="w-full border-collapse">
                <tbody>
                  <tr className="grid grid-cols-12">
                    <td class="pricingBorder col-span-6 text-white">.</td>
                    <td class="pricingBorder col-span-2">
                      <p className="planBorderRadius bg-[#FB2C6B] text-base text-white text-center font-secondary py-1 w-28 mx-auto mt-[-2px] mb-8">
                        Bronze
                      </p>
                      <p className="text-[32px] text-midBlue text-center font-secondary font-semibold mb-2">
                        $7,800
                      </p>
                      <p className="text-base text-midBlue text-center font-opensans mb-7">
                        per 6 months
                      </p>
                    </td>
                    <td class="pricingBorder col-span-2">
                      <p className="planBorderRadius bg-orange text-base text-white text-center font-secondary py-1 w-28 mx-auto mt-[-2px] mb-8">
                        Silver
                      </p>
                      <p className="text-[32px] text-midBlue text-center font-secondary font-semibold mb-2">
                        $15,000
                      </p>
                      <p className="text-base text-midBlue text-center font-opensans mb-7">
                        per 6 months
                      </p>
                    </td>
                    <td class="pricingBorder col-span-2">
                      <p className="planBorderRadius bg-[#003687] text-base text-white text-center font-secondary py-1 w-28 mx-auto mt-[-2px] mb-8">
                        Platinum
                      </p>
                      <p className="text-[32px] text-midBlue text-center font-secondary font-semibold mb-2">
                        $18,000
                      </p>
                      <p className="text-base text-midBlue text-center font-opensans mb-7">
                        per 6 months
                      </p>
                    </td>
                  </tr>
                  {plansData &&
                    plansData.map(({ text, checkSvg, subText }, i) => {
                      return (
                        <tr className="grid grid-cols-12" key={i}>
                          <td className="col-span-6 text-base leading-8 text-midBlue font-opensans font-semibold pl-6 py-3 pricingBorder">
                            {text}
                          </td>
                          {checkSvg &&
                            checkSvg.map((svg, i) => {
                              return (
                                <td
                                  className="col-span-2 pricingBorder grid place-items-center"
                                  key={i}
                                >
                                  <img src={svg} alt="" className="w-5" />
                                </td>
                              );
                            })}
                          {subText &&
                            subText.map((txt, i) => {
                              return (
                                <td
                                  className="col-span-2 pricingBorder grid place-items-center text-base text-midBlue font-opensans font-semibold"
                                  key={i}
                                >
                                  {txt}
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                  <tr className="grid grid-cols-12">
                    <td class="col-span-6 invisible">Invisible</td>
                    <td className="col-span-2 border-none px-3 pt-8">
                      <Link
                        to="/contactus.php"
                        className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-full hover:bg-newOrange-200 transition-colors duration-300"
                      >
                        Contact Us
                      </Link>
                    </td>
                    <td className="col-span-2 border-none px-3 pt-8">
                      <Link
                        to="/contactus.php"
                        className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-full hover:bg-newOrange-200 transition-colors duration-300"
                      >
                        Contact Us
                      </Link>
                    </td>
                    <td className="col-span-2 border-none px-3 pt-8">
                      <Link
                        to="/contactus.php"
                        className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-full hover:bg-newOrange-200 transition-colors duration-300"
                      >
                        Contact Us
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table class="w-full border-collapse">
                <tbody>
                  <tr className="w-full grid grid-cols-16">
                    <td class="pricingBorder col-span-4 px-6 py-32">
                      <p className="text-base text-midBlue font-opensans font-semibold">
                        {/* Initial campaign
                        <br />
                        investment:
                        <span className="block">" ONE SHOT " : 1 month</span> */}
                        Initial Campaign
                        <br />
                        Investments for One Month
                      </p>
                    </td>
                    <td class="pricingBorder px-3 pt-12 pb-6 col-span-3">
                      <p className="text-[34px] text-midBlue text-center font-secondary font-semibold mb-2">
                        $1,200
                      </p>
                      <p className="text-sm text-midBlue text-center font-opensans font-semibold mb-7">
                        (Bronze Partial Package w/o Maintence)
                      </p>
                      <ul className="list-disc text-sm text-midBlue font-opensans flex flex-col gap-1 pl-5 mb-6">
                        <li>20 pages</li>
                        <li>100 Key Phrases</li>
                        <li>Without Copywriting</li>
                      </ul>
                      <Link
                        to="/contactus.php"
                        className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-full hover:bg-newOrange-200 transition-colors duration-300"
                      >
                        Contact Us 
                      </Link>
                    </td>
                    <td class="pricingBorder px-3 pt-12 pb-6 col-span-3">
                      <p className="text-[34px] text-midBlue text-center font-secondary font-semibold mb-2">
                        $1,600
                      </p>
                      <p className="text-sm text-midBlue text-center font-opensans font-semibold mb-7">
                        (Silver Partial Package w/o Maintenance)
                      </p>
                      <ul className="list-disc text-sm text-midBlue font-opensans flex flex-col gap-1 pl-5 mb-6">
                        <li>30 pages</li>
                        <li>150 Key Phrases</li>
                        <li>Without Copywriting</li>
                      </ul>
                      <Link
                        to="/contactus.php"
                        className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-full hover:bg-newOrange-200 transition-colors duration-300"
                      >
                        Contact Us
                      </Link>
                    </td>
                    <td class="pricingBorder px-3 pt-12 pb-6 col-span-3">
                      <p className="text-[34px] text-midBlue text-center font-secondary font-semibold mb-2">
                        $2,200
                      </p>
                      <p className="text-sm text-midBlue text-center font-opensans font-semibold mb-7">
                        (Platinum Partial Package w/o Maintenance)
                      </p>
                      <ul className="list-disc text-sm text-midBlue font-opensans flex flex-col gap-1 pl-5 mb-6">
                        <li>40 pages</li>
                        <li>200 Key Phrases</li>
                        <li>Without Copywriting</li>
                      </ul>
                      <Link
                        to="/contactus.php"
                        className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-full hover:bg-newOrange-200 transition-colors duration-300"
                      >
                        Contact Us
                      </Link>
                    </td>
                    <td class="pricingBorder px-3 pt-12 pb-6 col-span-3">
                      <p className="text-[34px] text-midBlue text-center font-secondary font-semibold mb-2">
                        $3,200
                      </p>
                      <p className="text-sm text-midBlue text-center font-opensans font-semibold mb-7">
                        (Full Package)
                        <span className="invisible block">invisible</span>
                      </p>
                      <ul className="list-disc text-sm text-midBlue font-opensans flex flex-col gap-1 pl-5 mb-6">
                        <li>40 pages</li>
                        <li>400 Key Phrases</li>
                        <li>With Copywriting</li>
                      </ul>
                      <Link
                        to="/contactus.php"
                        className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-full hover:bg-newOrange-200 transition-colors duration-300"
                      >
                        Contact Us
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
          <div className="xl:hidden pt-[28px]">
            {plan == 1 ? (
              <div>
                <div className="rounded-lg shadow-planCard mb-8">
                  <p className="planBorderRadius bg-[#FB2C6B] text-lg text-white text-center font-secondary py-1 w-32 mx-auto mt-[-2px] mb-8">
                    Bronze
                  </p>
                  <p className="text-[50px] text-midBlue text-center font-secondary font-semibold mb-1">
                    $7,800
                  </p>
                  <p className="text-lg text-midBlue text-center font-opensans mb-7">
                    per 6 months
                  </p>
                  {!openPlan.bronze && (
                    <div
                      className="text-lg text-midBlue text-center font-opensans font-semibold pb-7 cursor-pointer flex items-center gap-3 justify-center"
                      onClick={() =>
                        setOpenPlan((value) => {
                          return {
                            ...value,
                            bronze: true,
                          };
                        })
                      }
                    >
                      <p>Show Features </p>
                      <img src={chevronDown} alt="open" className="w-5" />
                    </div>
                  )}
                  {openPlan.bronze && (
                    <div className="px-3 pb-8">
                      <ul className="list-disc flex flex-col gap-3 pl-[28px] pb-8 text-sm text-midBlue font-opensans">
                        {planBronzeData &&
                          planBronzeData.map(({ text }, i) => (
                            <li key={i}>{text}</li>
                          ))}
                      </ul>
                      <Link
                        to="/contactus.php"
                        className="text-base text-white text-center font-opensans font-bold py-3 bg-orange rounded block w-full hover:bg-newOrange-200 transition-colors duration-300"
                      >
                        Contact Us
                      </Link>
                    </div>
                  )}
                </div>
                <div className="rounded-lg shadow-planCard mb-8">
                  <p className="planBorderRadius bg-orange text-lg text-white text-center font-secondary py-1 w-32 mx-auto mt-[-2px] mb-8">
                    Silver
                  </p>
                  <p className="text-[50px] text-midBlue text-center font-secondary font-semibold mb-1">
                    $15,000
                  </p>
                  <p className="text-lg text-midBlue text-center font-opensans mb-7">
                    per 6 months
                  </p>
                  {!openPlan.silver && (
                    <div
                      className="text-lg text-midBlue text-center font-opensans font-semibold pb-7 cursor-pointer flex items-center gap-3 justify-center"
                      onClick={() =>
                        setOpenPlan((value) => {
                          return {
                            ...value,
                            silver: true,
                          };
                        })
                      }
                    >
                      <p>Show Features </p>
                      <img src={chevronDown} alt="open" className="w-5" />
                    </div>
                  )}
                  {openPlan.silver && (
                    <div className="px-3 pb-8">
                      <ul className="list-disc flex flex-col gap-3 pl-[28px] pb-8 text-sm text-midBlue font-opensans">
                        {planSilverData &&
                          planSilverData.map(({ text }, i) => (
                            <li key={i}>{text}</li>
                          ))}
                      </ul>
                      <Link
                        to="/contactus.php"
                        className="text-base text-white text-center font-opensans font-bold py-3 bg-orange rounded block w-full hover:bg-newOrange-200 transition-colors duration-300"
                      >
                        Contact Us
                      </Link>
                    </div>
                  )}
                </div>
                <div className="rounded-lg shadow-planCard mb-8">
                  <p className="planBorderRadius bg-[#003687] text-lg text-white text-center font-secondary py-1 w-32 mx-auto mt-[-2px] mb-8">
                    Platinum
                  </p>
                  <p className="text-[50px] text-midBlue text-center font-secondary font-semibold mb-1">
                    $18,000
                  </p>
                  <p className="text-lg text-midBlue text-center font-opensans mb-7">
                    per 6 months
                  </p>
                  {!openPlan.platinum && (
                    <div
                      className="text-lg text-midBlue text-center font-opensans font-semibold pb-7 cursor-pointer flex items-center gap-3 justify-center"
                      onClick={() =>
                        setOpenPlan((value) => {
                          return {
                            ...value,
                            platinum: true,
                          };
                        })
                      }
                    >
                      <p>Show Features </p>
                      <img src={chevronDown} alt="open" className="w-5" />
                    </div>
                  )}
                  {openPlan.platinum && (
                    <div className="px-3 pb-8">
                      <ul className="list-disc flex flex-col gap-3 pl-[28px] pb-8 text-sm text-midBlue font-opensans">
                        {planPlatinumData &&
                          planPlatinumData.map(({ text }, i) => (
                            <li key={i}>{text}</li>
                          ))}
                      </ul>
                      <Link
                        to="/contactus.php"
                        className="text-base text-white text-center font-opensans font-bold py-3 bg-orange rounded block w-full hover:bg-newOrange-200 transition-colors duration-300"
                      >
                        Contact Us
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div class="px-16 pt-12 pb-6 rounded-[10px] shadow-planCard w-[302px] mx-auto mb-8">
                  <p className="text-[34px] text-midBlue text-center font-secondary font-semibold mb-2">
                    $1,200
                  </p>
                  <p className="text-sm text-midBlue text-center font-opensans font-semibold mb-7">
                    (Bronze Partial Package w/o Maintence)
                  </p>
                  <ul className="list-disc text-sm text-midBlue font-opensans flex flex-col gap-1 pl-5 mb-6 justify-center">
                    <li>20 pages</li>
                    <li>100 Key Phrases</li>
                    <li>Without Copywriting</li>
                  </ul>
                  <Link
                    to="/contactus.php"
                    className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-40 hover:bg-newOrange-200 transition-colors duration-300"
                  >
                    Contact Us
                  </Link>
                </div>
                <div class="px-16 pt-12 pb-6 rounded-[10px] shadow-planCard w-[302px] mx-auto mb-8">
                  <p className="text-[34px] text-midBlue text-center font-secondary font-semibold mb-2">
                    $1,600
                  </p>
                  <p className="text-sm text-midBlue text-center font-opensans font-semibold mb-7">
                    (Silver Partial Package w/o Maintenance)
                  </p>
                  <ul className="list-disc text-sm text-midBlue font-opensans flex flex-col gap-1 pl-5 mb-6 justify-center">
                    <li>30 pages</li>
                    <li>150 Key Phrases</li>
                    <li>Without Copywriting</li>
                  </ul>
                  <Link
                    to="/contactus.php"
                    className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-40 hover:bg-newOrange-200 transition-colors duration-300"
                  >
                    Contact Us
                  </Link>
                </div>
                <div class="px-16 pt-12 pb-6 rounded-[10px] shadow-planCard w-[302px] mx-auto mb-8">
                  <p className="text-[34px] text-midBlue text-center font-secondary font-semibold mb-2">
                    $2,200
                  </p>
                  <p className="text-sm text-midBlue text-center font-opensans font-semibold mb-7">
                    (Platinum Partial Package w/o Maintenance)
                  </p>
                  <ul className="list-disc text-sm text-midBlue font-opensans flex flex-col gap-1 pl-5 mb-6 justify-center">
                    <li>40 pages</li>
                    <li>200 Key Phrases</li>
                    <li>Without Copywriting</li>
                  </ul>
                  <Link
                    to="/contactus.php"
                    className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-40 hover:bg-newOrange-200 transition-colors duration-300"
                  >
                    Contact Us
                  </Link>
                </div>
                <div class="px-16 pt-12 pb-6 rounded-[10px] shadow-planCard w-[302px] mx-auto mb-8">
                  <p className="text-[34px] text-midBlue text-center font-secondary font-semibold mb-2">
                    $3,200
                  </p>
                  <p className="text-sm text-midBlue text-center font-opensans font-semibold mb-7">
                    (Full Package)
                  </p>
                  <ul className="list-disc text-sm text-midBlue font-opensans flex flex-col gap-1 pl-5 mb-6 justify-center">
                    <li>40 pages</li>
                    <li>400 Key Phrases</li>
                    <li>With Copywriting</li>
                  </ul>
                  <Link
                    to="/contactus.php"
                    className="text-base text-white text-center font-opensans font-bold px-5 py-3 bg-orange rounded block w-40 hover:bg-newOrange-200 transition-colors duration-300"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultilingualSeoPricing;
