export const plansData = [
  {
    text: "Dedicated Account Management",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Initial Keyword research",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "On Page analysis + Correction",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Competitive Analysis",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Local SEO optimization (Local business citations optimization - X listings / directories)",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Title Tags & Meta descriptions & Header Tags",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Robots.txt optimization",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Number of keyphrases optimized",
    subText: ["Up to 100", "Up to 150", "Up to 200"],
  },
  {
    text: "Pages Optimized",
    subText: ["Up to 20", "Up to 30", "Up to 40"],
  },
  {
    text: "Technical Website Analysis & Opportunity Report",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "XML sitemap creation & submission",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Google My Business optimization (if needed)",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Canonicalization analysis",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "External Link Audit and Disavowal",
    checkSvg: [
      " ",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Schema markup Implementation",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Google Analytics traffic analysis",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Ongoing quarterly keyword ranking reporting",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Ongoing monthly traffic Analysis & insights",
    checkSvg: [
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "Monitor competitors' search rankings on a monthly basis",
    checkSvg: [
      " ",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
      "https://tomedes.gumlet.io/assets/midBlue-check.svg",
    ],
  },
  {
    text: "SEO Blog copywriting articles - 4 articles",
    checkSvg: [" ", " ", "https://tomedes.gumlet.io/assets/midBlue-check.svg"],
  },
  {
    text: "Outbound Link Acquisition 1 (DA 20-30)",
    checkSvg: ["https://tomedes.gumlet.io/assets/midBlue-check.svg", " ", " "],
  },
  {
    text: "Outbound Link Acquisition 2 (DA 35+)",
    checkSvg: [" ", "https://tomedes.gumlet.io/assets/midBlue-check.svg", ""],
  },
  {
    text: "Outbound Link Acquisition 3 - DA 50+ (4-8 links / month)",
    checkSvg: [" ", "", "https://tomedes.gumlet.io/assets/midBlue-check.svg"],
  },
];

// for mobile
export const planBronzeData = [
  {
    text: "Dedicated Account Management",
  },
  {
    text: "Initial Keyword research",
  },
  {
    text: "On Page analysis + Correction",
  },
  {
    text: "Competitive Analysis",
  },
  {
    text: "Local SEO optimization (Local business citations optimization - X listings / directories)",
  },
  {
    text: "Title Tags & Meta descriptions & Header Tags",
  },
  {
    text: "Robots.txt optimization",
  },
  {
    text: "Number of keyphrases optimized (Up to 100)",
  },
  {
    text: "Pages Optimized (Up to 20)",
  },
  {
    text: "Technical Website Analysis & Opportunity Report",
  },
  {
    text: "XML sitemap creation & submission",
  },
  {
    text: "Google My Business optimization (if needed)",
  },
  {
    text: "Canonicalization analysis",
  },
  {
    text: "Schema markup Implementation",
  },
  {
    text: "Google Analytics traffic analysis",
  },
  {
    text: "Ongoing quarterly keyword ranking reporting",
  },
  {
    text: "Ongoing monthly traffic Analysis & insights",
  },
  {
    text: "Outbound Link Acquisition 1 (DA 20-30)",
  },
];

export const planSilverData = [
  {
    text: "Dedicated Account Management",
  },
  {
    text: "Initial Keyword research",
  },
  {
    text: "On Page analysis + Correction",
  },
  {
    text: "Competitive Analysis",
  },
  {
    text: "Local SEO optimization (Local business citations optimization - X listings / directories)",
  },
  {
    text: "Title Tags & Meta descriptions & Header Tags",
  },
  {
    text: "Robots.txt optimization",
  },
  {
    text: "Number of keyphrases optimized (Up to 150)",
  },
  {
    text: "Pages Optimized (Up to 30)",
  },
  {
    text: "Technical Website Analysis & Opportunity Report",
  },
  {
    text: "XML sitemap creation & submission",
  },
  {
    text: "Google My Business optimization (if needed)",
  },
  {
    text: "Canonicalization analysis",
  },
  {
    text: "External Link Audit and Disavowal",
  },
  {
    text: "Schema markup Implementation",
  },
  {
    text: "Google Analytics traffic analysis",
  },
  {
    text: "Ongoing quarterly keyword ranking reporting",
  },
  {
    text: "Ongoing monthly traffic Analysis & insights",
  },
  {
    text: "Monitor competitors' search rankings on a monthly basis",
  },
  {
    text: "Outbound Link Acquisition 2 (DA 35+)",
  },
];

export const planPlatinumData = [
  {
    text: "Dedicated Account Management",
  },
  {
    text: "Initial Keyword research",
  },
  {
    text: "On Page analysis + Correction",
  },
  {
    text: "Competitive Analysis",
  },
  {
    text: "Local SEO optimization (Local business citations optimization - X listings / directories)",
  },
  {
    text: "Title Tags & Meta descriptions & Header Tags",
  },
  {
    text: "Robots.txt optimization",
  },
  {
    text: "Number of keyphrases optimized (Up to 200)",
  },
  {
    text: "Pages Optimized (Up to 40)",
  },
  {
    text: "Technical Website Analysis & Opportunity Report",
  },
  {
    text: "XML sitemap creation & submission",
  },
  {
    text: "Google My Business optimization (if needed)",
  },
  {
    text: "Canonicalization analysis",
  },
  {
    text: "External Link Audit and Disavowal",
  },
  {
    text: "Schema markup Implementation",
  },
  {
    text: "Google Analytics traffic analysis",
  },
  {
    text: "Ongoing quarterly keyword ranking reporting",
  },
  {
    text: "Ongoing monthly traffic Analysis & insights",
  },
  {
    text: "Monitor competitors' search rankings on a monthly basis",
  },
  {
    text: "SEO Blog copywriting articles - 4 articles",
  },
  {
    text: "Outbound Link Acquisition 3 - DA 50+ (4-8 links / month)",
  },
];
