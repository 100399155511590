export const FAQData = [
  {
    heading: "What is multilingual SEO?",
    para: "Multilingual SEO refers to the process of improving your website’s text content—copy, headers, tags—to make it resonate with how people search for content using search engines and improve the likelihood that it will be found, in different languages and around the world.",
    para2:
      "SEO in general refers to a whole host of practices that require a holistic knowledge of how search algorithms interact with websites, something which is always shifting (which is why it’s considered a specialist endeavor). It also demands a keen eye for how people do their searches on the web. Multilingual SEO professionals have all this expertise, and they do all the work with native proficiency in another language. What they bring to the table is insight regarding how the everyday web user conducts their search, which is the foundation of every good SEO strategy.",
  },
  {
    heading: "Why is translation not enough for SEO?",
    para: "SEO does place a few demands regarding the way language is used, which means that any translators or copywriters doing work on website text need to handle it in accordance with proper SEO practices. This not only helps boost rankings in the target language but also avoid potential penalties that search engines mete out against poorly optimized text.",
    para2:
      "Another reason is search intent—search intent is the primary vector in SEO that determines how keywords are generated and handled. This requires not just fluency in the target language, but a native understanding of how people actually phrase their searches in that language. For example, “translation company” translates into “compaña de traduccion” in Spanish, but internet users in Spain are more likely to search “empresas de traduccion” instead. SEO professionals are most capable at catching such nuances in their native language (which is why we exclusively assign native speakers to our language projects).",
  },

  {
    heading: "SEO and Website Localization",
    para: "Localization is the process of adapting existing content to be more suited to a different target audience. Culture, preferences, and conventions are among the things that are taken into consideration in localizing a product or service. In some cases, features or content may have to be completely altered or redone in order to be a good fit for the local audience. This is what takes it one notch higher than translation.",
    para2:
      "Because localization is so highly dependent on the unique features of the target audience, its benefits tend to be centered around that audience rather than finding broad appeal. But market studies have shown that consumers highly prefer and engage more meaningfully with brands and businesses that make the effort to cater to them, making localization the most recommended strategy for attracting a robust, loyal customer base.",
    para3:
      "A website that has been localized for a different language may not work as well in another precisely because of the differences between audiences. This can jeopardize the success of the entire website localization project. This is why website localization involving different languages needs to take multilingual SEO into account.",
    para4: "Website Translation",
  },
  {
    heading: "Multilingual vs International SEO",
    para: "These two terms tend to be used interchangeably, and the line isn’t always clearly drawn between them. But generally, the difference is mainly in what they’re centered around, which are implied by their names. Multilingual has to do with language, and as such is primarily concerned with content. International has to do with optimizing for different countries, may or may not entail different languages, and puts a heavier focus on the technical aspects.",
    para2:
      "At Tomedes, the solutions traditionally involved in both are subsumed within our comprehensive website localization suite. Our multilingual SEO services take best practices usually ascribed to both terms to put it in a class of its own. If you want to learn more, our agents are well-equipped to answer your questions and help you determine what solutions are appropriate for your particular project.",
  },
];
