import React, { useState } from "react";
import { Link } from "gatsby";
import chevronUp from "assets/chevron-up-orange.svg";
import chevronDown from "assets/chevron-down-white.svg";

function MultilingualSeoAccordion({
  heading,
  para,
  para2,
  para3,
  para4,
  para5,
}) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="card w-11/12 lg:w-[120%]  flex justify-between mb-2 flex-col mt-3">
      <div
        className="flex py-5 px-4  bg-[#00245A] justify-between items-center cursor-pointer rounded pl-[20px] pr-[20px] lg:pl-[42px] lg:pr-[20px]"
        onClick={() => setIsActive((active) => !active)}
      >
        <h3
          className={`text-xl sm:text-[22px] leading-8 text-white font-secondary my-auto mr-4`}
        >
          {heading}
        </h3>
        {isActive ? (
          <img src={chevronUp} alt="open" className="w-8 h-auto basis-8" />
        ) : (
          <img src={chevronDown} alt="open" className="w-8 h-auto basis-8" />
        )}
      </div>
      {isActive && (
        <div
          className={`transition-all ease-in-out delay-150  duration-300 px-4 md:px-10 mt-8 mb-9 `}
        >
          <p className="font-opensans text-lg leading-8 text-midBlue">{para}</p>
          {para2 && (
            <p className="font-opensans text-lg leading-8 text-midBlue mt-4">
              {para2}
            </p>
          )}
          {para3 && (
            <p className="font-opensans text-lg leading-8 text-midBlue mt-4">
              {para3}
            </p>
          )}
          {para4 && (
            <Link
              to="/website-translation.php"
              className="font-opensans text-lg leading-8 text-orange underline mt-4 inline-block hover:text-newOrange-100 transition-colors"
            >
              {para4}
            </Link>
          )}
          {para5 && (
            <p className="font-opensans text-lg leading-8 text-midBlue mt-4">
              {para5}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default MultilingualSeoAccordion;
