import React from "react";
import { Link } from "gatsby";

function MultilingualEighth() {
  return (
    <div className="bg-supportedLangs bg-no-repeat bg-cover bg-center">
      <div className="w-11/12 md:w-9/12 max-w-7xl mx-auto pt-[60px] pb-[84px]">
        <h2 className="text-[28px] sm:text-40 leading-9 sm:leading-[55px] text-midBlue text-center font-secondary font-semibold mb-6">
          SEO Services Available for&nbsp;
          <span className="sm:block">Multiple Languages</span>
        </h2>
        <p className="text-[22px] leading-8 sm:leading-9 text-center text-midBlue font-secondary mb-5">
          Our SEO services are available in any of our over 120 languages, and
          950+ language pairs. Remember, without multilingual SEO, website
          localization is not going to be effective.
        </p>
        <p className="text-lg leading-8 text-midBlue text-center font-opensans">
          Click here to learn more about our languages.
        </p>
        <div className="w-full text-center mt-7">
          <Link
            to="/language-translation-service.php"
            className="uppercase rounded-full bg-orange hover:bg-[#ff9a16] text-white cursor-pointer text-14 sm:text-17 font-semibold py-4 px-4 sm:px-8 text-center transition-all duration-300"
          >
            SEE OUR SUPPORTED LANGUAGES
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MultilingualEighth;
