import React from "react";
import img2 from "assets/images/multilingual-seo-services/languages.svg";
import { Link } from "gatsby";

function MultilingualThirdNew() {
  return (
    <div className="bg-midBlueNew">
      <div className="flex flex-col w-11/12 lg:w-10/12 2xl:w-9/12 max-w-7xl mx-auto pt-[80px] ">
        <div className="text-lightGrayNew mb-14">
          <h2 className="text-[28px] sm:text-[35px] leading-10 sm:leading-[55px] text-center font-medium font-secondary text-lightGrayNew max-w-3xl mx-auto mb-16">
            <span className="text-orange">
              Expand your website’s visibility
            </span>{" "}
            and achieve global growth with SEO specialists in 120+ languages
          </h2>
          <div className="md:flex gap-14">
            <img src={img2} alt="" className="hidden md:block self-start" />
            <div className="self-center">
              <p className="text-lg leading-8 text-lightGrayNew font-opensans mb-6">
                Our multilingual SEO services are brought to you exclusively by
                native-language SEO marketers, copywriters and website
                localization professionals who are versed in the specific, often
                mystifying demands of writing for search engines and their
                everyday users.
              </p>
              <p className="text-lg leading-8 text-lightGrayNew font-opensans mb-6">
                With Tomedes as your partner in language, reaching global
                audiences with your website is much more possible than ever.
                Tomedes has fostered a network of 20,000+ language professionals
                all over the world, and we’ve accomplished multilingual SEO
                projects in Arabic, French, German, Chinese, and many other
                languages.
              </p>
              <p className="text-lg leading-8 text-lightGrayNew font-opensans">
                Check our{" "}
                <Link
                  to="/language-translation-service.php"
                  className="text-orange underline hover:text-newOrange-100 transition-colors"
                >
                  full list
                </Link>{" "}
                of supported languages offered.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="bg-white sm:bg-multilingualGradient bg-no-repeat bg-cover bg-center py-20">
          <div className="max-w-4xl">
            <h2 className="text-40 leading-[50px] sm:text-45 sm:leading-[65px] text-midBlue font-secondary font-semibold mb-7">
              Project management
              <br />
              and communication <span className="text-orange">made easy</span>
            </h2>
            <p className="text-lg leading-8 text-midBlue font-opensans">
              You will be matched with a dedicated project manager who will
              oversee your account from start to finish. Our customer service
              line is open 24/7, ensuring that all your concerns are met at your
              convenience.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default MultilingualThirdNew;
