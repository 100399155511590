import React from "react";

function MultilingualFourth() {
  return (
    <section className="py-20 relative font-secondary">
      <div className="container mx-auto px-4">
        <h2 className="text-[28px] sm:text-4xl leading-10 sm:leading-[60px] text-midBlue text-center font-secondary font-semibold mb-6">
          Trusted by <span className="text-orange">95,000+</span> Satisfied
          Clients Worldwide
        </h2>
        <div className="mx-auto pt-10 text-center">
          <div className="grid gap-12 max-w-lg mx-auto  grid-cols-2 md:grid-cols-3 md:gap-10 lg:flex lg:flex-wrap lg:justify-center lg:space-x-6 lg:space-y-4 lg:max-w-4xl place-items-center place-content-center xl:grid xl:grid-cols-7">
            <img
              src="https://tomedes.gumlet.io/assets/clients/amazon.svg"
              alt="amazon"
              className="w-24 h-auto self-end xl:relative xl:bottom-2"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/google.svg"
              alt="google"
              className="w-20 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/microsoft.svg"
              alt="microsoft"
              className="w-24 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/youtube.svg"
              alt="youtube"
              className="w-24 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/bloomberg.svg"
              alt="bloomberg"
              className="w-24 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/spotify.svg"
              alt="spotify"
              className="w-20 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/logo-salesforce.svg"
              alt="CodeOrg"
              className="w-14"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/wix.svg"
              alt="wix"
              className="w-20 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/hsbc.svg"
              alt="hsbc"
              className="w-24 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/images/canon.svg"
              alt="canon"
              className="w-24 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/mgm.png"
              alt="mgm"
              className="w-11 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/code.svg"
              alt="code"
              className="w-9 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/clients/sap.svg"
              alt="sap"
              className="w-12 h-auto"
            />
            <img
              src="https://tomedes.gumlet.io/assets/logooecd_en_black.webp"
              alt="CodeOrg"
              className="w-[6rem]"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MultilingualFourth;
