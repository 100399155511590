import React from "react";
import ata from "assets/images/ata.png";
import iso from "assets/images/iso.png";
import ATA from "assets/images/new-homepage-assets/accreditation icons/ata.png";
import ISO from "assets/images/new-homepage-assets/accreditation icons/iso.png";
import ISO2 from "assets/new-assets/ISO_2007.svg";
import ISO3 from "assets/new-assets/ISO_17100.svg";

function MultilingualSeoCertificates() {
  return (
    <section className={`bg-white py-24`}>
      <div className=" px-4 flex items-center justify-center flex-col gap-y-7 lg:gap-y-16">
        <h2 className="text-[28px] sm:text-[35px] leading-9 sm:leading-[60px] text-midBlue text-center font-secondary font-semibold">
          <span className="text-orange">Ensured</span> By Certifying Institutional Bodies
        </h2>
        <ul className="w-auto gap-5 lg:gap-0 flex lg:flex-row flex-col justify-between items-center lg:relative pt-6 lg:pt-0">
          <li className="logos flex flex-col items-center w-[186px] pt-6 lg:pt-0">
            <img src="https://tomedes.gumlet.io/assets/certificates/ISO_9001.webp" alt="" className="lg:pb-12" />
            <div className=" lg:absolute lg:top-[6rem] w-[186px]">
              <p className="text-[12px] text-midBlue pt-4  text-center">ISO 9001:2015 </p>
              <p className="text-[12px] text-midBlue text-center">Quality Management Systems</p>
            </div>
          </li>
          <li className="logos flex flex-col items-center w-[186px] pt-6 lg:pt-0">
            <img src="https://tomedes.gumlet.io/assets/certificates/ISO_18587.webp" alt="" className="lg:pb-12" />
            <div className=" lg:absolute lg:top-[6rem] w-[186px]">
              <p className="text-[12px] text-midBlue pt-4  text-center">ISO 18587:2017 </p>
              <p className="text-[12px] text-midBlue text-center">Post-editing Machine Translation Output</p>
            </div>
          </li>
          <li className="logos flex flex-col items-center w-[186px] pt-6 lg:pt-0 sm:pb-0 pb-4">
            <img src="https://tomedes.gumlet.io/assets/certificates/ISO_17100.webp" alt="" className="lg:pb-12" />
            <div className=" lg:absolute lg:top-[6rem] w-[174px]">
              <p className="text-[12px] text-midBlue pt-4  text-center">ISO 17100:2015 </p>
              <p className="text-[12px] text-midBlue text-center">Translation Services Quality</p>
            </div>
          </li>
          <li className="logos flex flex-col items-center w-[186px]">
            <img src={ata} alt="" className="lg:pb-12" />
            <div className="  lg:absolute lg:top-[6rem] w-[146px]">
              <p className="text-[12px] text-midBlue pt-4  text-center ">ATA Corporate</p>
              <p className="text-[12px] text-midBlue text-center ">Member Number 272444</p>
            </div>
          </li>
          <li className="logos flex flex-col items-center w-[186px]">
            <img
              src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/clutch.png"
              alt=""
              className="lg:pb-12"
            />
            <div className="  lg:absolute lg:top-[6rem] w-[146px]">
              <p className="text-[12px] text-midBlue pt-4  text-center ">Clutch Top Global</p>
              <p className="text-[12px] text-midBlue text-center ">Translation Services 2021</p>
            </div>
          </li>
          <li className="logos flex flex-col items-center w-[186px]">
            <img
              src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/uscis.svg"
              alt=""
              className="lg:pb-12"
            />
            <div className="  lg:absolute lg:top-[6rem] w-[146px]">
              <p className="text-[12px] text-midBlue pt-4  text-center ">100% guaranteed</p>
              <p className="text-[12px] text-midBlue text-center ">acceptance by USCIS</p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default MultilingualSeoCertificates;
