import React from "react";
import img1 from "assets/images/multilingual-seo-services/img4.webp";
import MultilingualFourth from "./MultilingualFourth";

function MultilingualSecond() {
  const newBg = {
    background:
      "linear-gradient(180deg, #FFFFFF 0%,  #E8F1F5  100%) 0% 0% no-repeat padding-box",
  };
  return (
    <div
      className="bg-linearBlue bg-cover bg-center bg-no-repeat"
      style={newBg}
    >
      <div className="flex flex-col w-11/12 lg:w-10/12 2xl:w-9/12 max-w-7xl mx-auto pt-[80px] pb-[64px]">
        <div className="flex flex-col md:flex-row gap-11 lg:gap-0 mb-14 md:mb-28">
          <div className="self-center">
            <h2 className="text-[26px] md:text-[32px] leading-[38px] text-midBlue font-secondary font-semibold mb-8">
              Your website on the{" "}
              <span className="text-orange">front page </span>
              <span className="sm:block lg:inline xl:block">
                of search in different countries.&nbsp;
              </span>
              <span className="block pt-8 font-medium md:text-[26px]">
                We bring the behind-the-scenes magic to make it happen.
              </span>
            </h2>
            <p className="text-lg leading-8 font-opensans text-midBlue">
              If you’re not on the front page, then you don’t exist—that’s how
              difficult it is for websites to get and stay relevant in today’s
              web search environment.
            </p>
          </div>
          <img src={img1} alt="" className="hidden lg:block" />
        </div>
        <div className="max-w-4xl mr-auto pr-12">
          <p className="text-[26px] leading-[44px] text-midBlue font-secondary font-medium mb-8">
            It takes a <span className="text-orange">specialist’s</span> efforts
            to make a website rank highly on the SERPs, and when you throw one
            or two other languages into the mix then this becomes even more
            complicated.
          </p>
          <p className="text-lg leading-8 font-opensans text-midBlue">
            With Tomedes, both language and SEO are a breeze. Search intent,
            meta tags, long-tail keywords—you don’t have to worry about
            anything, our multilingual SEO specialists have you covered.
            <span className="block pt-8">
              We will match you with the right people for the job in terms of
              SEO expertise and language, and a dedicated account manager to
              handle all your project’s affairs is just one call away. And with
              our full-suite website localization services, you can broaden your
              horizons across the web and reach global audiences with ease.
            </span>
          </p>
        </div>
        {/* <MultilingualFourth /> */}
      </div>
    </div>
  );
}

export default MultilingualSecond;
